import { Autocomplete, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { NewZoneDetails } from "../../types/newZoneDetails";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { AppRoute } from "../../utils/const";
import CustomMap from "../../components/Zone/Map";
import { getUKName } from "../../utils/utils";
import { $houseListStore } from "../../store/HouseListData";
import { saveZone } from "./ZoneListApi";
import { $zoneTableDataStore } from "../../store/ZoneTableStore";
import { ButtonSquare } from "@synapse/frontend-react";
import { StyledCard } from "../../App";
import MainContentWrap from "../../ui/Wrappers/MainContentWrap";
import { $loginUserStore } from "../../store/ResponseData";
import { CustomCircularProgress } from "../../components/CustomCircularProgress";

const StyledTextField = styled(TextField)({
    margin: "19px 0 9px",
    borderRadius: "15px",
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "15px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa"
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "12px"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa"
    },
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    textWrap: "nowrap"
});

const AddNewZone = observer(() => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams()

    useEffect(() => {
        $houseListStore.setSearch('');
        setBackurl(searchParams.get('backurl') ?? generatePath(AppRoute.ZoneList));

        setSelectedHouse(searchParams.get('house') ?? '');
        $houseListStore.setSearch(searchParams.get('house') ?? '');

        setSelectedHouseAddress(searchParams.get('name') ?? '');

        if (searchParams.get('name') === null) {
            setSelectedFormHouse('');
        }
    }, [searchParams]);

    const [selectedHouse, setSelectedHouse] = useState('');
    const [selectedHouseAddress, setSelectedHouseAddress] = useState('');
    const [backurl, setBackurl] = useState(generatePath(AppRoute.ZoneList));
    const [selectedFormHouse, setSelectedFormHouse] = useState(selectedHouseAddress);
    const [showButtons, setShowButtons] = useState(false);
    const [saving, setSaving] = useState(false);
    const [form, setForm] = useState<NewZoneDetails>({
        id: "",
        coordinates: undefined,
        description: "",
        house: selectedHouse,
        uk: "",
        guid: "",
        name: selectedHouseAddress
    });

    if (!$loginUserStore.isAdmin()) {
        navigate(AppRoute.ZoneList);
    }

    const { zoneCoordinates } = $zoneTableDataStore;

    const setFormValue = useCallback(
        (name: string, value: string) => {
            setForm({ ...form, [name]: value });
        },
        [form]
    );

    const {
        data,
        houseSearch,
    } = $houseListStore;

    const addresses = useMemo(() => {
        return data?.items.map((item) => item.address);
    }, [data?.items]);

    const handleFormChange = useCallback(
        (evt: SyntheticEvent<Element, Event> | undefined, value: string | unknown) => {
            if (value === undefined || value === null) {
                value = '';
            }
            setForm({
                ...form,
                uk: value !== ''
                    ? data?.items.find((item) => item.address === value)?.uk!
                    : '',
                name: value as string,
                house: data?.items.find((item) => item.address === value)?.guid!
            });
            setSelectedFormHouse(value as string);

            $houseListStore.setSearch(value as string);

            $zoneTableDataStore.setNewCoordinates(
                data?.items.find((item) => item.address === value)?.point!
            );
        },
        [data?.items, form]
    );

    useEffect(() => {
        $houseListStore.setSearch(selectedHouse);

        if (selectedHouseAddress !== '' && data?.items.length !== undefined) {
            setTimeout(() => {
                setSelectedFormHouse(selectedHouseAddress);
                handleFormChange(undefined, selectedHouseAddress);
            }, 1000);
        }
    }, [selectedHouse, selectedHouseAddress, data?.items]);

    const handleClick = () => {
        $zoneTableDataStore.setShowZoneDetailes(false);
        navigate(generatePath(AppRoute.ZoneList));
    };

    useEffect(() => {
        setFormValue("coordinates", JSON.stringify(zoneCoordinates));
    }, [zoneCoordinates, setFormValue]);

    useEffect(() => {
        if (selectedFormHouse && zoneCoordinates) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [selectedFormHouse, zoneCoordinates]);

    // при изменении адреса сбрасываем сохраненные координаты зоны
    useEffect(() => {
        $zoneTableDataStore.setCoordinates(undefined);
    }, [selectedFormHouse]);

    useEffect(() => {
        $houseListStore.getData(houseSearch);
    }, [houseSearch]);

    const handleSubmit = async () => {
        setSaving(true);
        $zoneTableDataStore.setLoadingZoneTable(true);
        await saveZone(form).then((zone) => {
            if (zone) {
                $houseListStore.setSearch('');
                navigate(backurl);
                setSaving(false);
            }
        });
        $zoneTableDataStore.setLoadingZoneTable(false);
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em"
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Создание новой зоны</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <StyledCard>
                    <Grid container direction={"row"} justifyContent={"space-evenly"} spacing={3}>
                        <Grid item xs={8} lg={8} sm={7} md={8}>
                            {
                                selectedFormHouse === ''
                                    ? selectedHouse !== ''
                                        ? <div
                                                style={{
                                                    height: 700,
                                                    border: '1px solid #5ac8fa',
                                                }}
                                            ><CustomCircularProgress/></div>
                                        : <div
                                            style={{
                                                height: 700,
                                                margin: 'auto',
                                                border: '1px solid #5ac8fa',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >Не выбран адрес</div>
                                    : <CustomMap
                                        style={{
                                            height: 700,
                                            border: '1px solid #5ac8fa',
                                        }}
                                    />
                            }
                        </Grid>
                        <Grid item xs={4} lg={4} sm={5} md={4}>
                            <StyledCard
                                style={{
                                    height: "auto"
                                }}
                            >
                                <Grid
                                    container
                                    direction={"column"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                    style={{
                                        maxWidth: 360,
                                        maxHeight: "75vh"
                                    }}
                                >
                                    <Grid item>
                                        <form
                                            noValidate
                                            style={{
                                                maxWidth: 360
                                            }}
                                        >
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    spacing={2}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Grid item xs={12}>
                                                        <StyledAutocomplete
                                                            noOptionsText={"Адрес не найден"}
                                                            id="name"
                                                            value={
                                                                selectedFormHouse !== ""
                                                                    ? selectedFormHouse
                                                                    : null
                                                            }
                                                            options={
                                                                addresses !== undefined
                                                                    ? addresses
                                                                    : []
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    placeholder="Адрес"
                                                                    label="Адрес"
                                                                />
                                                            )}
                                                            onChange={handleFormChange}
                                                            onInputChange={(e, value) => {
                                                                $houseListStore.setSearch(value);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    spacing={2}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Grid item xs={12}>
                                                        <StyledTextField
                                                            placeholder="Управляющая компания"
                                                            // label="Управляющая компания"
                                                            onKeyDown={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                            size="medium"
                                                            variant="outlined"
                                                            fullWidth
                                                            id="uk"
                                                            name="uk"
                                                            value={
                                                                form.uk === ''
                                                                    ? ''
                                                                    : getUKName(form.uk)
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction={"row"}
                                                    alignItems={"center"}
                                                    spacing={2}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Grid item xs={12}>
                                                        <StyledTextField
                                                            placeholder="Описание"
                                                            label="Описание"
                                                            size="medium"
                                                            variant="outlined"
                                                            fullWidth
                                                            name="description"
                                                            id="description"
                                                            value={form.description}
                                                            onChange={(value) =>
                                                                setFormValue(
                                                                    "description",
                                                                    value.target.value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </Grid>
                                    {showButtons && (
                                        <Grid
                                            container
                                            item
                                            direction={"row"}
                                            spacing={2}
                                            justifyContent={"space-between"}
                                        >
                                            <Grid item>
                                                <ButtonSquare
                                                    variant="contained"
                                                    onClick={handleSubmit}
                                                    disabled={saving}
                                                >
                                                    Создать
                                                </ButtonSquare>
                                            </Grid>
                                            <Grid item>
                                                <ButtonSquare
                                                    variant="outlined"
                                                    onClick={handleClick}
                                                    disabled={saving}
                                                >
                                                    Отмена
                                                </ButtonSquare>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </StyledCard>
                        </Grid>
                    </Grid>
                </StyledCard>
            </>
        </MainContentWrap>
    );
});

export default AddNewZone;
