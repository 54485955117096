import { Autocomplete, Dialog, DialogTitle, Grid, IconButton, TextField, Typography, } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { $contractsDataStore } from "../../store/ContractsStore";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { addContractHouse } from "../../Pages/Contracts/ContractsApi";
import { getOrganisationName, getUKName } from "../../utils/utils";
import { contractAddress } from "../../types/contractAddress";

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px",
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    textWrap: "nowrap",
});

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

const StyledTextField = styled(TextField)({
    margin: "0 0 10px",
    borderRadius: "15px",
    background: "#fff",
    ".MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5ac8fa",
        borderRadius: "15px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#5ac8fa",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px",
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff",
        },
    },
});

const AddHouse = observer(() => {
    const {
        contractAddressPage,
        contractAddressRowsPerPage,
        contractAddressSort,
        contractDetail,
        contractAddressSearch,
    } = $contractsDataStore;

    const [form, setForm] = useState<contractAddress>({
        guid: "",
        contract: contractDetail?.guid!,
        house: "",
        status: "WORK",
        name: "",
        zones: {
            items: [],
        },
    });

    const addresses = useMemo(() => {
        return contractDetail?.available_houses?.items.map((item) => {
            return {
                id: item.id,
                label: item.name,
            }
        });
    }, [contractDetail?.available_houses?.items]);

    const handleFormChange = useCallback(
        (
            evt: SyntheticEvent<Element, Event> | undefined,
            value: string | unknown
        ) => {
            setForm({
                ...form,
                house: (value as any).id,
            });
        },
        [form]
    );

    const handleSubmit = async () => {
        $contractsDataStore.setLoading(true);
        await addContractHouse(
            form
        ).then((contract) => {
            $contractsDataStore.getHouseList(
                String(contractDetail?.guid),
                String(contractAddressPage + 1),
                contractAddressSort.sort.by,
                contractAddressSort.sort.order,
                String(contractAddressRowsPerPage),
                contractAddressSearch
            );
            $contractsDataStore.getDetail(
                String(contractDetail?.guid)
            );
            $contractsDataStore.setAddNewHouse(false);
        });
        $contractsDataStore.setAddressLoading(false);
    };

    const contractName = $contractsDataStore.getName(contractDetail);
    const contractFrom = contractDetail?.from ? getUKName(contractDetail?.from) : ' ';
    const contractTo = contractDetail?.to ? getOrganisationName(contractDetail?.to) : ' ';

    return (
        <StyledDialog
            open={$contractsDataStore.addNewHouse}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset",
                },
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white",
                }}
                onClick={() => $contractsDataStore.setAddNewHouse(false)}
            >
                <CloseIcon fontSize="large" />
            </IconButton>

            <Grid container justifyContent={"center"}>
                <Grid item xs={9}>
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"center"}
                        spacing={2}
                    >
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Typography
                                    variant={"h1"}
                                    style={{
                                        fontSize: "34px",
                                        lineHeight: "36px",
                                        letterSpacing: "0",
                                        fontWeight: "600",
                                        color: "#20a0ff",
                                    }}
                                    component={"span"}
                                >
                                    Добавить дом к договору
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <form
                            noValidate
                            style={{
                                paddingLeft: "18px",
                                maxWidth: "100%",
                            }}
                        >
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={12}>
                                        <StyledTextField
                                            label="Управляющая компания"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            value={contractFrom}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={12}>
                                        <StyledTextField
                                            label="Организация"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            value={contractTo}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={12}>
                                        <StyledTextField
                                            label="Номер договора"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            value={contractName}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction={"row"}
                                    alignItems={"center"}
                                    spacing={2}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item xs={12}>
                                        <StyledAutocomplete
                                            noOptionsText={
                                                "Адрес не найден"
                                            }
                                            id="house"
                                            value={
                                                contractDetail?.available_houses?.items.find(
                                                    (item) => item.id === form.house
                                                )?.id
                                            }
                                            options={
                                                addresses !== undefined
                                                    ? addresses
                                                    : []
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Адрес"
                                                    label="Адрес"
                                                />
                                            )}
                                            onChange={handleFormChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <ButtonSquare
                                variant="contained"
                                color="primary"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "15px auto",
                                }}
                                onClick={handleSubmit}
                            >
                                Добавить
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});
export default AddHouse;
