import { makeAutoObservable } from "mobx";
import { DEFAULT_ROWS_PER_PAGE } from "../utils/const";
import { OrderType } from "../types/order.sort";
import { ContractInfo, ContractList } from "../types/contractListInfo";
import {
    getContractDetail,
    getContractListInfo,
    getHouseListByContract,
    getMembersByContract,
} from "../Pages/Contracts/ContractsApi";
import throttle from "lodash.throttle";
import { contractAddress, contractAddressList } from "../types/contractAddress";
import { contractMember, contractMembersList } from "../types/contractMember";
import { formatDate } from "../utils/utils";

class ContractsStore {
    contractDetail?: ContractInfo| undefined;
    contractsData?: ContractList;
    contractsSearch: string = "";
    contractsPage: number = 0;
    contractsRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    contractsloadingTable: boolean = true;
    contractsSort: OrderType = {
        sort: {
            by: "id",
            order: "asc",
        },
    };
    addNewContract: boolean = false;
    houseList: contractAddressList | undefined;

    contractAddressPage: number = 0;
    contractAddressRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    contractAddressloadingTable: boolean = true;
    contractAddressSort: OrderType = {
        sort: {
            by: "address",
            order: "asc",
        },
    };
    contractAddressSearch: string = '';

    contractMembersList: contractMembersList | undefined;
    contractMembersPage: number = 0;
    contractMembersRowsPerPage: number = DEFAULT_ROWS_PER_PAGE;
    contractMembersLoadingTable: boolean = true;
    contractMembersSort: OrderType = {
        sort: {
            by: "guid",
            order: "asc",
        },
    };
    contractMembersSearch: string = '';

    addNewHouse: boolean = false;
    addNewUser: boolean = false;

    addressEdit: boolean = false;
    addressEditData: contractAddress = {
        guid: "",
        contract: "",
        house: "",
        status: "WORK",
        name: "",
        zones: {
            items: [],
        },
    };

    memberEdit: boolean = false;
    memberEditData: contractMember = {
        guid: "",
        contract: "",
        user: "",
        status: "WORK",
        name: "",
    };

    constructor() {
        makeAutoObservable(this);
    }

    setPage(page: number) {
        this.contractsPage = page;
    }

    setRowsPerPage(rowsPerPage: number) {
        this.contractsRowsPerPage = rowsPerPage;
    }

    setLoading(isLoading: boolean) {
        this.contractsloadingTable = isLoading;
    }

    setSort(sort: OrderType) {
        this.contractsSort = sort;
    }

    setData(data: ContractList) {
        this.contractsData = data;
    }

    setDetail(data: ContractInfo | undefined) {
        this.contractDetail = data;
    }

    setAddNewHouse(status: boolean) {
        this.addNewHouse = status;
    }

    setAddNewUser(status: boolean) {
        this.addNewUser = status;
    }

    setAddNewContract(status: boolean) {
        this.addNewContract = status;
    }

    setHouseList(data: contractAddressList | undefined) {
        this.houseList = data;
    }

    setMembersList(data: contractMembersList | undefined) {
        this.contractMembersList = data;
    }

    getData(
        page: string,
        orderBy: string,
        direction: string,
        pageSize: string,
        search: string
    ) {
        this.setLoading(true);
        const getContractList = async () => {
            return await getContractListInfo(
                page,
                orderBy,
                direction,
                pageSize,
                search
            );
        };
        const throttledGetContractList = throttle(getContractList, 100);
        throttledGetContractList()?.then((res) => {
            if (res) {
                this.setData(res);
            }
            this.setLoading(false);
        });
    }

    getDetail(
        guid: string
    ) {
        this.setDetail(undefined);
        this.setHouseList(undefined);
        this.setMembersList(undefined);

        const getContractDetails = async () => {
            return await getContractDetail(guid);
        };
        const throttledGetContractDetails = throttle(getContractDetails, 10);
        throttledGetContractDetails()?.then((res) => {
            if (res) {
                this.setDetail(res);
            }
        });
    }

    getHouseList(
        contract: string,
        page: string,
        orderBy: string,
        direction: string,
        pageSize: string,
        search: string
    ) {
        this.setHouseList(undefined);
        this.setAddressLoading(true);
        const getHouses = async () => {
            return await getHouseListByContract(
                contract,
                page,
                orderBy,
                direction,
                pageSize,
                search
            );
        };
        const throttledGetHouseList = throttle(getHouses, 10);
        throttledGetHouseList()?.then((res) => {
            if (res) {
                this.setHouseList(res);
                this.setAddressLoading(false);
            }
        });
    }

    getMembersList(
        contract: string,
        page: string,
        orderBy: string,
        direction: string,
        pageSize: string,
        search: string
    ) {
        this.setMembersList(undefined);
        this.setMembersLoading(true);
        const getMembers = async () => {
            return await getMembersByContract(
                contract,
                page,
                orderBy,
                direction,
                pageSize,
                search
            );
        };
        const throttledGetMembersList = throttle(getMembers, 10);
        throttledGetMembersList()?.then((res) => {
            if (res) {
                this.setMembersList(res);
                this.setMembersLoading(false);
            }
        });
    }

    setAddressPage(page: number) {
        this.contractAddressPage = page;
    }

    setAddressRowsPerPage(rowsPerPage: number) {
        this.contractAddressRowsPerPage = rowsPerPage;
    }

    setAddressLoading(isLoading: boolean) {
        this.contractAddressloadingTable = isLoading;
    }

    setAddressSort(sort: OrderType) {
        this.contractAddressSort = sort;
    }

    setAddressEdit(status: boolean) {
        this.addressEdit = status;
    }

    setAddressEditData(data: contractAddress) {
        this.addressEditData = data;
    }

    setMemberEdit(status: boolean) {
        this.memberEdit = status;
    }

    setMemberEditData(data: contractMember) {
        this.memberEditData = data;
    }

    setMembersPage(page: number) {
        this.contractMembersPage = page;
    }

    setMembersRowsPerPage(rowsPerPage: number) {
        this.contractMembersRowsPerPage = rowsPerPage;
    }

    setMembersLoading(isLoading: boolean) {
        this.contractMembersLoadingTable = isLoading;
    }

    setMembersSort(sort: OrderType) {
        this.contractMembersSort = sort;
    }

    setSearch(data: string) {
        this.contractsSearch = data;
    }

    setAddressSearch(data: string) {
        this.contractAddressSearch = data;
        this.setAddressPage(0);
    }

    setMembersSearch(data: string) {
        this.contractMembersSearch = data;
        this.setMembersPage(0);
    }

    getName(data: ContractInfo | undefined) {
        if (data === undefined && this.contractDetail !== undefined) {
            data = this.contractDetail;
        }
        return (data?.number
                ? data?.number
                : '')
            +
            (data?.date
                ? ' от ' + formatDate(data?.date, 'd.m.y')
                : '')
    }
}

export const $contractsDataStore = new ContractsStore();
