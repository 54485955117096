import axios from "../../axios";
import { toast } from "react-toastify";
import { Members } from "../../types/member";
import Notification from "../../components/Notification";

export async function getMembers(
    page: string,
    orderBy: string,
    direction: string,
    pageSize: string,
    search?: string,
    organisation?: string
) {
    let data = {
        page: page,
        orderBy: orderBy,
        direction: direction,
        pageSize: pageSize,
        search: search,
        organisation: organisation,
    };

    let config = {
        method: "post",
        url: "/UserService/Users_list",
        data: data,
    };

    try {
        const response = await axios.request(config);
        if (!response) return;
        const result: Members = response.data.data;

        if (!result) {
            return;
        }
        if (result.items.length === undefined) {
            return {
                ...result,
                items: [],
            };
        }
        return result;
    } catch (error: any) {
        Notification({
            title: 'Не удалось загрузить список сотрудников',
            type: toast.TYPE.ERROR,
        });
    }
}
