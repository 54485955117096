import { makeAutoObservable } from "mobx";

class AnchorRefData {
    data?: null | HTMLElement;
    item?: any;

    constructor() {
        makeAutoObservable(this);
    }

    setAnchorRef(elem: null | HTMLElement) {
        this.data = elem;
    }

    setAnchorItem(elem: any) {
        this.item = elem;
    }
}

export const $anchorRefStore = new AnchorRefData();
