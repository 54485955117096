import { observer } from "mobx-react";
import { $contractsDataStore } from "../../store/ContractsStore";
import * as React from "react";
import { useState } from "react";
import { Dialog, DialogTitle, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from "@mui/material/styles";
import { ButtonSquare, ExportButton } from '@synapse/frontend-react';
import 'dayjs/locale/ru';
import dayjs from "dayjs";
import { $deviceTableDataStore } from "../../store/DeviceTableStore";
import axios from "../../axios";
import Notification from "../Notification";
import { toast } from "react-toastify";
import { StyledTextField } from '../../Pages/Contracts/ContractDetail';

const StyledDatePicker = styled(DatePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    width: "250px !important",
    background: "white",
    borderRadius: 12,
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px 0 0 12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});
const StyledDatePickerSecond = styled(StyledDatePicker)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderLeftWidth: 0,
            borderRadius: "0 12px 12px 0"
        },
    }
});

const ContractReport = observer(() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(dayjs(yesterday).startOf('month'));
    const [end, setEnd] = useState(dayjs(yesterday));
    const [mailDialogOpen, setMailDialogOpen] = useState(false);
    const [mail, setMail] = useState('');

    const {
        contractDetail,
    } = $contractsDataStore;

    const exportReport = async () => {
        setLoading(true);
        const request = {
            contract: contractDetail?.guid,
            start: start.format('YYYY-MM-DD'),
            end: end.format('YYYY-MM-DD'),
        };

        let config = {
            method: "post",
            url: "/GeoService/Reporting_zonesreport",
            data: request,
        };

        try {
            const response = await axios.request(config);

            const data = response?.data?.data;

            require('downloadjs')(atob(data.content), data.name, data.content_type);
        } catch (error: any) {
            Notification({
                title: 'Не удалось выгрузить отчёт',
                type: toast.TYPE.ERROR,
            });
        }
        setLoading(false);
    };

    const mailReport = () => {
        setLoading(true);

        // Регулярное выражение для проверки email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Проверка, соответствует ли переданный email регулярному выражению
        if (!emailRegex.test(mail)) {
            Notification({
                title: 'Неверный формат email',
                type: toast.TYPE.ERROR,
            });
            setLoading(false);
            return;
        }

        const request = {
            contract: contractDetail?.guid,
            start: start.format('YYYY-MM-DD'),
            end: end.format('YYYY-MM-DD'),
            mail,
        };

        let config = {
            method: "post",
            url: "/GeoService/Reporting_mailZonesReport",
            data: request,
        };

        Notification({
            title: 'Отчет отправляется. После отправки письма вы получите дополнительное уведомление',
            type: toast.TYPE.SUCCESS,
        });
        axios.request(config)
            .then(() => {
                Notification({
                    title: 'Отчет отправлен на почту ' + request.mail,
                    type: toast.TYPE.SUCCESS,
                });
            })
            .catch(error => {
                Notification({
                    title: 'Не удалось отправить отчет на почту',
                    type: toast.TYPE.ERROR,
                });
            });
        setMailDialogOpen(false);
        setLoading(false);
    }

    return (<>
        <Grid
            container
            style={{
                minHeight: 250,
            }}
        >
            <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                    <StyledDatePicker
                        label="Дата с"
                        format="DD.MM.YYYY"
                        minDate={dayjs('2024-07-01')}
                        maxDate={dayjs(yesterday)}
                        defaultValue={start}
                        name="start"
                        slotProps={{
                            field: {
                                clearable: false,
                            },
                        }}
                        onChange={(value) => value && setStart(value)}
                    />
                    <StyledDatePickerSecond
                        label="Дата по"
                        minDate={dayjs('2024-07-01')}
                        maxDate={dayjs(yesterday)}
                        defaultValue={end}
                        format="DD.MM.YYYY"
                        name="end"
                        slotProps={{
                            field: {
                                clearable: false,
                            },
                        }}
                        onChange={(value) => value && setEnd(value)}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item>
                <ExportButton
                    onClick={exportReport}
                    loading={loading}
                    disabled={loading}
                    style={{
                        height: 56,
                    }}
                />
            </Grid>
            <Grid item>
                <ExportButton
                    onClick={() => setMailDialogOpen(true)}
                    loading={loading}
                    disabled={loading}
                    style={{
                        height: 56,
                    }}
                >
                    Отправить на почту
                </ExportButton>
            </Grid>
        </Grid>
        <Dialog open={mailDialogOpen} onClose={() => setMailDialogOpen(false)}>
            <Grid container
                  spacing={1}
                  sx={{padding: '25px'}}
                  justifyContent={'center'}
            >
                <Grid item xs={12}>
                    <DialogTitle textAlign={'center'}>
                        Отправка отчета на почту
                    </DialogTitle>
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField
                        label="Электронная почта"
                        InputProps={{
                            type: 'email',
                        }}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setMail(e.target.value)}
                        value={mail}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'}>
                        <Grid item>
                            <ButtonSquare variant={'contained'}
                                          onClick={mailReport}
                            >
                                Отправить
                            </ButtonSquare>
                        </Grid>
                        <Grid item>
                            <ButtonSquare onClick={() => setMailDialogOpen(false)}>
                                Отмена
                            </ButtonSquare>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    </>);
});
export default ContractReport;
