import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    CircularProgress,
    Grid,
    TextField
} from "@mui/material";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import { MonitoringItem } from "../../types/monitoring";
import { $monitoringStore } from "../../store/MonitoringStore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
    ArrowBackIosNew,
    ArrowForwardIos,
    CheckBox,
    CheckBoxOutlineBlank,
    Directions,
    DirectionsOff,
    Visibility,
    VisibilityOff
} from "@mui/icons-material";
import MonitoringMap from "./MonitoringMap";
import { MapContainer, TileLayer } from "react-leaflet";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import UserInfo from "./UserInfo";
import TuneIcon from "@mui/icons-material/Tune";
import Tooltip from "@mui/material/Tooltip";

const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
        padding: '5px',

        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    },
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    textWrap: "nowrap",
    width: 350,
    background: "white",
    borderRadius: 12
});

const StyledDatePicker = styled(DatePicker)({
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontSize: "16px",
    color: "#1d1d1b",
    width: "150px !important",
    background: "white",
    borderRadius: 12,
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#5ac8fa",
            borderRadius: "12px 0 0 12px"
        },
        "&:hover fieldset": {
            borderColor: "#20a0ff"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#20a0ff"
        }
    }
});

const Monitoringv3 = observer(() => {
    const { filters, usersDrawerIsOpen, mapCenter, loadingUsersData, selectedOptions } =
        $monitoringStore;
    const [showFilter, setShowFilter] = useState(false);

    // const cityListData = $monitoringStore.filterData.city?.items;
    const ukListData = $monitoringStore.filterData.uk?.items;
    const orgListData = $monitoringStore.filterData.organisation?.items;
    const contractListData = $monitoringStore.filterData.contract?.items;
    const userListData = $monitoringStore.filterData.user?.items;

    const getStoreData = (data: MonitoringItem[] = []) => {
        if (Array.isArray(data)) {
            return data.slice().sort((a, b) => a?.name.localeCompare(b?.name));
        } else {
            return [];
        }
    };

    // const cityList = getStoreData(cityListData);
    const ukList = getStoreData(ukListData);
    const orgList = getStoreData(orgListData);
    const contractList = getStoreData(contractListData);
    const userList = getStoreData(userListData);

    const handleFilterChangeDate = useCallback((value: string) => {
        if (value === undefined) {
            value = "";
        }
        $monitoringStore.setFilterDate(value);
    }, []);

    const handleFilterChangeOptions = useCallback((name: string, arrayItems: MonitoringItem[]) => {
        const strOfGuid = arrayItems.map((item) => item.id).join(",");
        $monitoringStore.setFilterOption(name, strOfGuid);
        $monitoringStore.setSelectedOption(name, arrayItems);
    }, []);

    return (
        <div>
            <MapContainer
                style={{
                    height: "100vh",
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    bottom: 0
                }}
                zoom={17}
                center={[mapCenter[1], mapCenter[0]]}
                maxZoom={17}
            >
                <Box
                    style={{
                        position: "absolute",
                        zIndex: 9999,
                        left: "calc(50vw - 50px)",
                        top: "calc(50vh - 50px)",
                        display: loadingUsersData ? "block" : "none"
                    }}
                >
                    <CircularProgress disableShrink />
                </Box>
                <ButtonGroup
                    variant="outlined"
                    style={{
                        background: "white",
                        position: "absolute",
                        zIndex: 9999,
                        top: 15,
                        left: 15,
                        borderRadius: "12px"
                    }}
                >
                    <Tooltip
                        arrow
                        slotProps={{
                            popper: {
                                style: {
                                    zIndex: 999999,
                                    width: "fit-content",
                                    whiteSpace: "pre-line",
                                    textAlign: "center"
                                }
                            }
                        }}
                        title={filters.show_zones === 1 ? "Скрыть ГЕО-зоны" : "Показать ГЕО-зоны"}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => $monitoringStore.toggleShowZones()}
                            style={{
                                borderRadius: "12px 0 0 12px"
                            }}
                        >
                            {filters.show_zones === 1 ? <Visibility /> : <VisibilityOff />}
                        </Button>
                    </Tooltip>

                    <Tooltip
                        arrow
                        slotProps={{
                            popper: {
                                style: {
                                    zIndex: 999999,
                                    width: "fit-content",
                                    whiteSpace: "pre-line",
                                    textAlign: "center"
                                }
                            }
                        }}
                        title={
                            filters.user.length <= 0
                                ? "Маршруты доступны при выборе\r\nсотрудников в фильтре"
                                : filters.show_zones === 1
                                ? "Скрыть маршруты"
                                : "Показать маршруты"
                        }
                    >
                        <Button
                            variant="outlined"
                            onClick={() => $monitoringStore.toggleShowRoutes()}
                            disabled={filters.user.length <= 0}
                            style={{
                                pointerEvents: "auto",
                                cursor: filters.user.length <= 0 ? "not-allowed" : "pointer"
                            }}
                        >
                            {filters.show_routes === 1 ? <Directions /> : <DirectionsOff />}
                        </Button>
                    </Tooltip>

                    <Tooltip
                        arrow
                        slotProps={{
                            popper: {
                                style: {
                                    zIndex: 999999,
                                    width: "fit-content",
                                    whiteSpace: "pre-line",
                                    textAlign: "center"
                                }
                            }
                        }}
                        title={
                            usersDrawerIsOpen
                                ? "Скрыть панель сотрудников"
                                : "Показать панель сотрудников"
                        }
                    >
                        <Button
                            variant="outlined"
                            onClick={() => $monitoringStore.toggleDrawer()}
                            style={{
                                borderRadius: "0 12px 12px 0"
                            }}
                        >
                            {usersDrawerIsOpen ? <ArrowForwardIos /> : <ArrowBackIosNew />}
                        </Button>
                    </Tooltip>
                </ButtonGroup>

                <ButtonGroup
                    variant="outlined"
                    style={{
                        background: "white",
                        position: "absolute",
                        zIndex: 9999,
                        top: 65,
                        left: 15,
                        borderRadius: "12px"
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
                        <StyledDatePicker
                            format="DD.MM.YYYY"
                            defaultValue={dayjs()}
                            name="date"
                            timezone="system"
                            sx={{
                                borderRadius: "12px"
                            }}
                            slotProps={{
                                field: {
                                    clearable: false
                                },
                                popper: {
                                    style: {
                                        zIndex: 99999
                                    }
                                }
                            }}
                            onChange={(value) =>
                                handleFilterChangeDate(value ? value.format("YYYY-MM-DD") : "")
                            }
                        />
                    </LocalizationProvider>

                    <Tooltip
                        arrow
                        slotProps={{
                            popper: {
                                style: {
                                    zIndex: 999999
                                }
                            }
                        }}
                        title={showFilter ? "Скрыть фильтры" : "Показать фильтры"}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setShowFilter(!showFilter);
                            }}
                            style={{
                                borderRadius: "0 12px 12px 0"
                            }}
                        >
                            <TuneIcon />
                        </Button>
                    </Tooltip>
                </ButtonGroup>
                <div
                    style={{
                        position: "absolute",
                        left: 15,
                        top: 135,
                        zIndex: 9999,
                        background: "white",
                        borderRadius: "12px",
                        padding: "15px",
                        display: showFilter ? "block" : "none"
                    }}
                >
                    <Grid container item justifyContent="flex-start" direction="column" spacing={1}>
                        {/* <Grid item>
                            <StyledAutocomplete
                                noOptionsText={"Города отсутствуют"}
                                id="city"
                                options={cityList}
                                value={
                                    cityListData?.find(
                                        (item) => item.id === filters.city
                                    )?.id
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Город"
                                    />
                                )}
                                onChange={(e, v) => {
                                    const guid = cityListData?.find(
                                        (item) => item.name === v
                                    )?.id;
                                    handleFiltersChange("city", guid);
                                }}
                            />
                        </Grid> */}
                        <Grid item>
                            <StyledAutocomplete
                                multiple
                                noOptionsText={"УК отсутствуют"}
                                id="uk"
                                options={ukList}
                                value={selectedOptions.uk}
                                getOptionLabel={(option: any) => option.name}
                                renderOption={(props, option: any, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBox fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option: any, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option.name}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Управляющая компания"
                                    />
                                )}
                                onChange={(e, v) => {
                                    handleFilterChangeOptions("uk", v as MonitoringItem[]);
                                }}
                                componentsProps={{
                                    popper: {
                                        style: {
                                            minWidth: 350,
                                            width: "fit-content",
                                            zIndex: 99999
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <StyledAutocomplete
                                multiple
                                disabled={!filters.uk}
                                noOptionsText={"Организации отсутствуют"}
                                id="organisation"
                                value={selectedOptions.organisation}
                                isOptionEqualToValue={(option: any, value: any) =>
                                    option.id === value.id
                                }
                                options={orgList}
                                getOptionLabel={(option: any) => option.name}
                                renderOption={(props, option: any, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBox fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option: any, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option.name}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Организация" />
                                )}
                                onChange={(e, v) => {
                                    handleFilterChangeOptions(
                                        "organisation",
                                        v as MonitoringItem[]
                                    );
                                }}
                                componentsProps={{
                                    popper: {
                                        style: {
                                            minWidth: 350,
                                            width: "fit-content",
                                            zIndex: 99999
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <StyledAutocomplete
                                multiple
                                disabled={!filters.organisation || !filters.uk}
                                noOptionsText={"Договоры отсутствуют"}
                                id="contract"
                                value={selectedOptions.contract}
                                options={contractList}
                                getOptionLabel={(option: any) => option.name}
                                renderOption={(props, option: any, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBox fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option: any, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option.name}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Договор" />
                                )}
                                onChange={(e, v) => {
                                    handleFilterChangeOptions("contract", v as MonitoringItem[]);
                                }}
                                componentsProps={{
                                    popper: {
                                        style: {
                                            minWidth: 350,
                                            width: "fit-content",
                                            zIndex: 99999
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <StyledAutocomplete
                                multiple
                                disabled={!filters.contract || !filters.organisation || !filters.uk}
                                noOptionsText={"Сотрудники отсутствуют"}
                                id="user"
                                value={selectedOptions.user}
                                options={userList}
                                getOptionLabel={(option: any) => option.name}
                                renderOption={(props, option: any, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                                            checkedIcon={<CheckBox fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option: any, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option.name}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Сотрудник" />
                                )}
                                onChange={(e, v) => {
                                    handleFilterChangeOptions("user", v as MonitoringItem[]);
                                }}
                                componentsProps={{
                                    popper: {
                                        style: {
                                            minWidth: 350,
                                            width: "fit-content",
                                            zIndex: 99999
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item></Grid>
                    </Grid>
                </div>
                <MonitoringMap />
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </MapContainer>
            <UserInfo />
        </div>
    );
});

export default Monitoringv3;
