import { Dialog, DialogTitle, Grid, IconButton, Typography, } from "@mui/material";
import { ButtonSquare } from "@synapse/frontend-react";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const StyledDialog = styled(Dialog)({
    height: "auto",
    padding: "15px",
    margin: "0 auto",
});

type ConfirmDialogProps = {
    display: boolean;
    title: string;
    submitText: string;
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
}

const ConfirmDialog = observer((props: ConfirmDialogProps) => {
    return (
        <StyledDialog
            open={props.display}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
                    width: 692,
                    overflowY: "unset",
                },
            }}
            keepMounted={true}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            maxWidth={false}
            scroll="body"
        >
            <IconButton
                aria-label="close"
                style={{
                    position: "absolute",
                    right: "-45px",
                    top: "-45px",
                    color: "white",
                }}
                onClick={props.onClose}
            >
                <CloseIcon fontSize="large" />
            </IconButton>

            <Grid container justifyContent="center">
                <Grid item xs={9}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        spacing={2}
                    >
                        <Grid item xs={7}>
                            <DialogTitle
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    style={{
                                        fontSize: "34px",
                                        lineHeight: "36px",
                                        letterSpacing: "0",
                                        fontWeight: "600",
                                        color: "#20a0ff",
                                    }}
                                    component="span"
                                >
                                    {props.title}
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        {props.children}
                        <Grid
                            container
                            flexDirection={"row"}
                            justifyContent={"center"}
                        >
                            <Grid item>
                                <ButtonSquare
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        margin: '10px 15px',
                                    }}
                                    onClick={props.onSubmit}
                                >
                                    {props.submitText}
                                </ButtonSquare>
                            </Grid>
                            <Grid item>
                                <ButtonSquare
                                    variant="outlined"
                                    style={{
                                        margin: '10px 15px',
                                    }}
                                    onClick={props.onClose}
                                >
                                    Отмена
                                </ButtonSquare>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </StyledDialog>
    );
});

export default ConfirmDialog;
