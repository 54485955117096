import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { formatDate, getOrganisationName, getUKName } from "../../utils/utils";
import { $contractsDataStore } from "../../store/ContractsStore";
import { ContractInfo } from "../../types/contractListInfo";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoute, ContractStatus } from "../../utils/const";
import { useCallback } from "react";

const ContractTableLine = observer(() => {
    if (
        $contractsDataStore.contractsData?.items.length === undefined ||
        $contractsDataStore.contractsData?.items.length === 0
    ) {
        return <TableRow>
            <TableCell
                colSpan={7}
                align="center"
            >
                Нет договоров
            </TableCell>
        </TableRow>;
    }

    let navigate = useNavigate();
    const handleClick = useCallback(
        (tableLine: ContractInfo) => {
            navigate(
                generatePath(AppRoute.ContractDetail, {
                    guid: String(tableLine.guid),
                })
            );
        },
        [navigate]
    );

    return (
        <>
            {$contractsDataStore.contractsData?.items?.map(
                (tableLine: ContractInfo) => {
                    return (
                        <TableRow
                            key={tableLine.guid}
                            onDoubleClick={() => {
                                handleClick(tableLine);
                            }}
                            component={"tr"}
                            sx={{
                                position: "relative",
                                "&:hover": {
                                    backgroundColor: "#f8f8f8 !important",
                                    boxShadow:
                                        "inset 0 0 4px rgba(0, 0, 0, 0.06)",
                                },
                            }}
                        >
                            <TableCell style={{ textWrap: "nowrap" }}>
                                {getUKName(tableLine.from)}
                            </TableCell>
                            <TableCell style={{ textWrap: "nowrap" }}>
                                {getOrganisationName(tableLine.to)}
                            </TableCell>
                            <TableCell style={{ textWrap: "nowrap" }}>
                                {$contractsDataStore.getName(tableLine)}
                            </TableCell>
                            <TableCell>
                                {formatDate(tableLine.start, 'd.m.y')}
                            </TableCell>
                            <TableCell>
                                {formatDate(tableLine.end, 'd.m.y')}
                            </TableCell>
                            <TableCell>{tableLine.description}</TableCell>
                            <TableCell style={{ textWrap: "nowrap" }}>{
                                tableLine?.status
                                    ? ContractStatus[ tableLine?.status as keyof typeof ContractStatus ]
                                    : null
                            }</TableCell>
                        </TableRow>
                    );
                }
            )}
        </>
    );
});

export default ContractTableLine;
