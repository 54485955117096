import { makeAutoObservable } from "mobx";
import { LoginResponseData } from "../types/response.data";

class LoginUserStore {
    data?: LoginResponseData | undefined;
    token?: string | undefined;
    administrator: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setData(data: LoginResponseData | undefined) {
        this.data = data;

        if (data !== undefined) {
            this.setToken(
                data.token.token_type +
                ' ' +
                data.token.access_token
            );
        }

        if (data?.roles.items.length !== undefined) {
            const adminRoles = data?.roles.items.filter(item => item.name === 'okolo_administrator');
            this.setAdministrator(adminRoles.length > 0);
        }
    }

    setToken(token: string) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }

    setAdministrator(status: boolean) {
        this.administrator = status;
    }

    isAdmin() {
        return this.administrator;
    }
}

export const $loginUserStore = new LoginUserStore();
