import { IconButton, TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { $contractsDataStore } from "../../store/ContractsStore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { contractMember } from "../../types/contractMember";
import { ContractRelationStatus } from "../../utils/const";
import { $anchorRefStore } from "../../store/AnchorRef";
import { CustomCircularProgress } from "../CustomCircularProgress";
import { $loginUserStore } from "../../store/ResponseData";

const ContractCardMembersTableLine = observer(() => {
    const {
        contractMembersList,
        contractMembersRowsPerPage,
        contractMembersPage,
        contractDetail,
        contractMembersLoadingTable,
    } = $contractsDataStore;

    if (contractDetail === undefined) {
        return <></>;
    }

    const counter = contractMembersList?.items?.filter((item) => {
        return item.contract === contractDetail?.guid
    });

    if (contractMembersLoadingTable || counter === undefined) {
        return <TableRow>
            <TableCell
                colSpan={4}
            >
                <CustomCircularProgress />
            </TableCell>
        </TableRow>;
    }

    if (counter.length <= 0) {
        return <TableRow>
            <TableCell
                colSpan={4}
                align="center"
            >
                Нет добавленных сотрудников
            </TableCell>
        </TableRow>;
    }

    return (
        <>
            {contractMembersList?.items?.map(
                (tableLine: contractMember, index) => {
                    return (
                        <TableRow
                            key={tableLine.guid}
                            component={"tr"}
                            // sx={{
                            //     position: "relative",
                            //     "&:hover": {
                            //         backgroundColor: "#f8f8f8 !important",
                            //         boxShadow:
                            //             "inset 0 0 4px rgba(0, 0, 0, 0.06)",
                            //     },
                            // }}
                        >
                            <TableCell style={{ textWrap: "nowrap" }}>{
                                (contractMembersRowsPerPage * contractMembersPage) +
                                index + 1
                            }</TableCell>
                            <TableCell style={{ textWrap: "nowrap" }}>
                                {tableLine.name}
                            </TableCell>
                            {/*<TableCell></TableCell>*/}
                            {/*<TableCell></TableCell>*/}
                            {/*<TableCell></TableCell>*/}
                            <TableCell style={{ textWrap: "nowrap" }}>{
                                tableLine?.status
                                    ? ContractRelationStatus[ tableLine?.status as keyof typeof ContractRelationStatus ]
                                    : null
                            }</TableCell>
                            {
                                $loginUserStore.isAdmin()
                                    ? <TableCell>
                                        <IconButton onClick={(event) => {
                                            $anchorRefStore.setAnchorRef(event.currentTarget);
                                            $anchorRefStore.setAnchorItem(tableLine);
                                        }}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </TableCell>
                                    : null
                            }
                        </TableRow>
                    );
                }
            )}
        </>
    );
});

export default ContractCardMembersTableLine;
