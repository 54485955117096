import { makeAutoObservable } from "mobx";
import { HouseList } from "../types/houseListInfo";
import { getHouseListInfo } from "../api-actions/api-actions";
import throttle from "lodash.throttle";

class HouseListData {
    data?: HouseList;
    houseSearch: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    setData(data: HouseList) {
        this.data = data;
    }

    setSearch(str: string) {
        this.houseSearch = str;
    }

    getData(
        houseSearch: string
    ) {
        const getHouseList = async () => {
            return await getHouseListInfo(houseSearch);
        };
        const throttledGetHouseList = throttle(getHouseList, 100);
        throttledGetHouseList()?.then((res) => {
            if (res) {
                this.setData(res);
            }
        });
    }
}

export const $houseListStore = new HouseListData();
