import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo } from "react";
import { $membersDataStore } from "../../store/MembersStore";
import { SearchField } from "@synapse/frontend-react";
import { StyledCard } from "../../App";
import MainContentWrap from "../../ui/Wrappers/MainContentWrap";
import Table from "../../ui/Table/Table";
import { Column } from "../../types/ui/table";

const Members = observer(() => {
    const {
        membersPage,
        membersRowsPerPage,
        membersloadingTable,
        membersSort,
        membersSearch,
        membersData,
        organisation
    } = $membersDataStore;

    const columns = useMemo<Column[]>(
        () => [
            {
                accessorKey: "name",
                header: "ФИО сотрудника",
                size: 300
            },
            {
                accessorKey: "organisation.name",
                header: "Организация",
                size: 700
            }
        ],
        []
    );

    useEffect(() => {
        $membersDataStore.getData(
            String(membersPage + 1),
            membersSort.sort.by,
            membersSort.sort.order,
            String(membersRowsPerPage),
            membersSearch,
            organisation
        );
    }, [membersSearch, membersPage, membersRowsPerPage, membersSort, organisation]);

    const changeTableSort = useCallback(
        (by: string, order: string) => {
            $membersDataStore.setSort({
                sort: {
                    by,
                    order: order === "asc" ? "asc" : "desc"
                }
            });
        },
        [membersSort]
    );

    const handleChangePagination = (pageIndex: number, pageSize: number) => {
        if (pageIndex !== membersPage) {
            $membersDataStore.setPage(pageIndex);
        }

        if (pageSize !== membersRowsPerPage) {
            $membersDataStore.setRowsPerPage(pageSize);
        }
    };

    return (
        <MainContentWrap>
            <>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                        paddingBottom: "1em",
                        paddingLeft: ".75em"
                    }}
                >
                    <Grid item>
                        <Grid container direction={"row"} spacing={2} alignItems={"baseline"}>
                            <Grid item>
                                <h3>Сотрудники</h3>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <SearchField
                            value={membersSearch}
                            callback={(value) => $membersDataStore.setSearch(value)}
                            callbackTimeout={700}
                            style={{
                                width: 300
                            }}
                        />
                    </Grid>
                </Grid>
                <StyledCard>
                    <Table
                        data={membersData?.items}
                        columns={columns}
                        pageIndex={membersPage}
                        pageSize={membersRowsPerPage}
                        count={$membersDataStore.membersData?.pagination?.total! ?? -1}
                        paginationChange={handleChangePagination}
                        loading={membersloadingTable}
                        changeTableSort={changeTableSort}
                        sortProps={membersSort.sort}
                    />
                </StyledCard>
            </>
        </MainContentWrap>
    );
});
export default Members;
