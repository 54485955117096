import { IconButton, TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { $deviceTableDataStore } from "../../store/DeviceTableStore";
import { Device, DeviceAttach } from "../../types/device";
import { formatDateTime } from "../../utils/utils";
import { $anchorRefStore } from "../../store/AnchorRef";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Circle } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { $loginUserStore } from "../../store/ResponseData";

require('dayjs/locale/ru');

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('ru');

function timeAgo(time: Date | string) {
    const dayjsLocal = dayjs(time);
    // const dayjsMoscow = dayjsLocal.add(addHours, 'hour');
    return dayjsLocal.fromNow()
}

const DeviceTableLine = observer(() => {
    if (
        $deviceTableDataStore.deviceData?.items.length === undefined ||
        $deviceTableDataStore.deviceData?.items.length === 0
    ) {
        return <TableRow>
            <TableCell
                colSpan={9}
                align="center"
            >
                Нет устройств
            </TableCell>
        </TableRow>;
    }

    return (
        <>
            {$deviceTableDataStore.deviceData?.items.map(
                (tableLine: Device) => {
                    let attaches: string[] = [];
                    if (tableLine.attach.items.length !== undefined) {
                        tableLine.attach.items.map(
                            (attach: DeviceAttach) => {
                                return attaches.push(
                                    attach.name +
                                    ' (с ' +
                                    formatDateTime(attach.start, '', 'd.m.Y H:i:s') +
                                    (attach.end !== ''
                                        ? ' по ' + formatDateTime(attach.end, '', 'd.m.Y H:i:s')
                                        : '') +
                                    ')'
                                );
                            }
                        );
                    }

                    return (
                        <TableRow
                            key={tableLine.guid}
                            component={"tr"}
                            sx={{
                                position: "relative",
                                "&:hover": {
                                    backgroundColor: "#f8f8f8 !important",
                                    boxShadow:
                                        "inset 0 0 4px rgba(0, 0, 0, 0.06)",
                                },
                            }}
                        >
                            <TableCell
                                style={{
                                    textWrap: "nowrap",
                                }}
                            >
                                {tableLine.imei}
                            </TableCell>
                            <TableCell style={{ textWrap: "nowrap" }}>
                                {tableLine.model}
                            </TableCell>
                            {
                                $loginUserStore.isAdmin()
                                    ? <TableCell style={{ textWrap: "nowrap" }}>
                                        {tableLine.phone}
                                    </TableCell>
                                    : null
                            }
                            <TableCell>{tableLine.name}</TableCell>
                            <TableCell>{tableLine.description}</TableCell>
                            <TableCell style={{ textWrap: "nowrap" }}>
                                <Tooltip
                                    arrow
                                    title={
                                        tableLine.status_date
                                            ? formatDateTime(tableLine.status_date, '', 'd.m.y H:i:s')
                                            : 'Никогда'
                                    }
                                >
                                    <span
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Circle
                                            htmlColor={tableLine.status === 'online'
                                                ? 'green'
                                                : tableLine.status === 'offline'
                                                    ? 'red'
                                                    : 'black'}
                                            style={{
                                                fontSize: 14,
                                                marginRight: 5,
                                            }}
                                        />
                                        <span>
                                            {
                                                tableLine.status_date
                                                    ? timeAgo(new Date(tableLine.status_date))
                                                    : ''
                                            }
                                        </span>
                                    </span>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <Tooltip
                                    arrow
                                    title={
                                        tableLine.last_online
                                            ? formatDateTime(tableLine.last_online, '', 'd.m.y H:i:s')
                                            : 'Никогда'
                                    }
                                ><span>{
                                    tableLine.last_online
                                        ? timeAgo(new Date(tableLine.last_online))
                                        : 'Никогда'
                                }</span></Tooltip>
                            </TableCell>
                            <TableCell>{attaches.map((item) => {
                                return (<p style={{margin: 0}}>
                                    {item}
                                </p>);
                            })}</TableCell>
                            {
                                $loginUserStore.isAdmin()
                                    ? <TableCell style={{ textAlign: "center" }}>
                                        <IconButton onClick={(event) => {
                                            $anchorRefStore.setAnchorRef(event.currentTarget);
                                            $anchorRefStore.setAnchorItem(tableLine);
                                        }}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </TableCell>
                                    : null
                            }
                        </TableRow>
                    );
                }
            )}
        </>
    );
});

export default DeviceTableLine;
